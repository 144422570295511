import * as React from 'react';
import Layout from '../components/layout';
import FormB from '../components/common/form-b';
import SEO from '../components/seo';

const text = {
  title: 'Request a Demo',
  description: 'Use the form below to get in touch with the LogRocket team for a personalized demo.',
  buttonValue: 'Request a Demo'
}

const IndexPage = () => (
  <Layout>
    <SEO title='LogRocket - Request a demo' description={['LogRocket helps you understand problems affecting your users, so that you can get back to building great software.']} />

    <div className='demo-request-container'>
      <div className='card-container__with_title card-container demo-request-container'>

        <div className='row m-0 p-0 justify-content-center'>
          <div className='card-overflow col-md-12 p-0 info-card  null null card-overflow-right'>
            <div className='row justify-content-end false justify-content-md-start false demo-request-q223'>

              <div className='col-col-12 col-md-5 null false false false d-flex mx-auto false-5 align-items-start'>
                <div className='d-flex flex-column justify-content-center   align-items-start false'>
                  <FormB text={text} isMessage={false} />
                </div>
              </div>

              <div className='col-md-7 demo- col-12 col-md-7 d-flex px-5 false-right justify-content-center align-items-start'>
                <div className='demo-request-right-container d-flex flex-column justify-content-center align-items-start false'>
                  <h2>You&apos;re in great company</h2>
                  <p>Join 3,000+ companies using LogRocket to discover impactful issues</p>
                  <div className='demo-request-right-logo-box'>
                    <div className='logo-row'>
                      <img alt="Ramp company logo" src='https://firebasestorage.googleapis.com/v0/b/logrocket-com.appspot.com/o/ramp.svg?alt=media&token=553da340-64c3-491f-a542-921e1e8b3c03' />
                      <img alt="Carta company logo" src='https://firebasestorage.googleapis.com/v0/b/logrocket-com.appspot.com/o/carta.svg?alt=media&token=4ff0b1f5-815e-425c-abea-7b6280514637' />
                      <img alt="Blue cross Blue shield company logo" src='https://firebasestorage.googleapis.com/v0/b/logrocket-com.appspot.com/o/bcbs.svg?alt=media&token=c414cf34-c2de-4b87-b8c7-46b15d01a6d5' />
                      <img alt="Classpass company logo" src='https://firebasestorage.googleapis.com/v0/b/logrocket-com.appspot.com/o/classpass.svg?alt=media&token=8af52628-7d75-4fb3-8e35-3d83927dcf54' />
                    </div>
                    <div className='logo-row'>
                      <img alt="Pintrest company logo" src='https://firebasestorage.googleapis.com/v0/b/logrocket-com.appspot.com/o/pinterest.svg?alt=media&token=62f1f75e-d3e8-40df-9912-40ecd2b8c0b1' />
                      <img alt="Cushman company logo" src='https://firebasestorage.googleapis.com/v0/b/logrocket-com.appspot.com/o/cushman.svg?alt=media&token=0e82308f-6fe0-4894-b9b6-589c3dd8806e' />
                      <img alt="Blizzard company logo" src='https://firebasestorage.googleapis.com/v0/b/logrocket-com.appspot.com/o/blizzard.svg?alt=media&token=b758ea86-9b6a-42ef-83f8-1e0708359c37' />
                      <img alt="MGM company logo" src='https://firebasestorage.googleapis.com/v0/b/logrocket-com.appspot.com/o/mgm.svg?alt=media&token=383a3bd7-b902-4924-a9f0-d7c7138d27f2' />
                    </div>
                    <div className='logo-row'>
                      <img alt="Ikea company logo" src='https://firebasestorage.googleapis.com/v0/b/logrocket-com.appspot.com/o/ikea.svg?alt=media&token=8829f421-fb54-4f43-87c2-124039c7ad7e' />
                      <img alt="Rippling company logo" src='https://firebasestorage.googleapis.com/v0/b/logrocket-com.appspot.com/o/rippling.svg?alt=media&token=400c901d-6935-4f61-9095-4b3f5b1d2bd6' />
                      <img alt="Brex company logo" src='https://firebasestorage.googleapis.com/v0/b/logrocket-com.appspot.com/o/brex.svg?alt=media&token=e0d56c28-3957-4015-b071-34a0c8c77343' />
                      <img alt="Swiss Life company logo" src='https://firebasestorage.googleapis.com/v0/b/logrocket-com.appspot.com/o/swisslife.svg?alt=media&token=32879217-7270-4e85-bf4b-7662435dada4' />
                    </div>
                  </div>
                  <img alt="decorative background" className='demo-bottom' src='https://cdn.sanity.io/images/mr2nzgx7/production/d54c1fd5a93f4fba0cb8fc0a4d4b3b275966a170-7904x3501.png?rect=0,0,7903,3501&w=2560&h=1134&auto=format' />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default IndexPage;

import React from 'react';

export interface FormProps {
  text: {
    title: string,
    description: string,
    buttonValue: string
  },
  isMessage: boolean
}

const FormComponent = ({ text, isMessage }: FormProps) => {

  const handleSubmit = (event) => {
    event.preventDefault();
    const {
      elements: {
        email: { value: email },
        phone: { value: phone },
        name: { value: name },
        company: { value: company },
        message: { value: message } = { value: '' },
      },
    } = event.target;

    const data = {
      email,
      phone,
      name,
      company,
      onprem: false,
      message,
      is_trial_request: false,
      formName: 'requestDemo',
    };

    window.lr_analytics.identify(data.email, data);
    window.lr_analytics.track('filled-form', data);
    window._vis_opt_queue = window._vis_opt_queue || [];
    window._vis_opt_queue.push(() => { window._vis_opt_goal_conversion(213); });

    const submitElement = document.querySelector('#request-demo-form button');
    const submit = submitElement as HTMLButtonElement;

    submit.disabled = true;
    submit.value = 'Sending...';

    const onSuccess = () => {
      document.querySelector('#request-demo-submitted').classList.remove('hidden');
      document.querySelector('#request-demo-form').classList.add('hidden');
    };

    const onClose = () => {
      const thankyouElement = document.querySelector('#request-demo-form button') as HTMLHeadElement;
      thankyouElement.innerText = "Couldn't Find a Time? No worries.";
      onSuccess();
    };

    const sendHook = () => window.fetch('https://hooks.zapier.com/hooks/catch/3092116/gcbsyi/', {
      method: 'POST',
      body: JSON.stringify(data),
    });

    const captureException = (err) => window.LogRocket && window.LogRocket.captureException(err);

    const options = {
      onSuccess,
      onClose,
      onError: onSuccess,
    };

    if (window.ChiliPiper && window.sixDomain) {
      window.ChiliPiper.submit('logrocket', 'Inbound_Router_Web', options);
      sendHook().catch(captureException);
    } else {
      sendHook().then(onSuccess).catch((err) => {
        document.querySelector('#request-demo-submitted').classList.remove('hidden');
        document.querySelector('#request-demo-form').classList.add('hidden');
        captureException(err);
      });
    }


    event.preventDefault();
    return false;
  }

  return <section className='form-section-b'>
    <div>
      <h1>{text.title}</h1>
      <p>{text.description}</p>
    </div>
    <div className='form-container'>
      <form id='request-demo-form' onSubmit={handleSubmit}>
        <div className='form-row'>
          <div className='form-group'>
            <label htmlFor='inputEmail4' className='w-100'>
              <input placeholder="Your work email" type='email' className='form-control mt-1' id='inputEmail4' name='email' required />
            </label>
          </div>
        </div>
        <div className='form-row'>
          <div className='form-group'>
            <label htmlFor='inputName4' className='w-100'>
              <input placeholder="Your name" className='form-control mt-1' id='inputName4' name='name' type='text' required />
            </label>
          </div>
        </div>
        <div className='form-row'>
          <div className='form-group'>
            <label htmlFor='inputPhoneNumber4' className='w-100'>
              <input placeholder="Your phone number" className='form-control mt-1' id='inputPhoneNumber4' name='phone' type='tel' required />
            </label>
          </div>
        </div>
        <div className='form-row'>
          <div className='form-group'>
            <label htmlFor='inputCompanyName4' className='w-100'>
              <input placeholder="Your company name" className='form-control mt-1' id='inputCompanyName4' name='company' type='text' required />
            </label>
          </div>
        </div>
        {isMessage && <div className='mb-5 mx-2'>
          <label htmlFor='Textarea' className='w-100'>Message
            <textarea className='form-control mt-1' id='validationTextarea' name='message' required />
          </label>
        </div>}
        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flext-start' }}>
          {text?.buttonValue && <button type='submit' value='submit' className='btn btn-primary'>
            {text.buttonValue}
          </button>}
        </div>
      </form>
      <div className='hidden' id='request-demo-submitted'>
        <h3 id='thank-you-text'>Thank you!</h3>
        <p>We&apos;ll be in touch soon.</p>
      </div>
    </div>
  </section>
};
export default FormComponent

